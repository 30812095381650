:root {
  --color-primary: #00cbdc;
  --color-secondary: #ff9500;
  --color-default: #ffffff;
  --color-neutral: #7487a1;
  --color-dark: #101835;
  --color-success: #00ac53;
  --color-info: #db5b00;
  --color-shadow: #00cbdc33;
  --color-gradient-body: linear-gradient(135deg, #121e49 0%, #004261 100%);
  --color-gradient-active: linear-gradient(
    180deg,
    #008df3 0%,
    #007ce7 12%,
    #00b6d2 89%,
    #00cddd 100%
  );
  --color-gradient-dark: linear-gradient(135deg, #191a34 0%, #072f46 100%);
}

body {
  margin: 0;
  height: 100vh;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-gradient-body);
  color: var(--color-default);
  font-weight: 500;
  overflow: auto;
}

#root {
  height: 100%;
  overflow: auto;
}
